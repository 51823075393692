import { ICigar, ICigarLog, ICigarLogListType } from '@models';

export function isListHumidor(list: ICigarLogListType) {
  if (!list) return false;
  return !['journal', 'wishlist', 'favorites'].includes(list);
}

export function isCigarLogHumidor(log: ICigarLog) {
  return isListHumidor(log.List);
}

export function isCigarLog(item: ICigar | ICigarLog): item is ICigarLog {
  return (item as ICigarLog).CigarDetails !== undefined;
}
